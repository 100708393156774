import '../style/Experience.scss';


function Experience(props) {

  return (
    <div className='experienceContainer' id='experience' ref={props.experienceRef}>
        
    </div>
  );
}

export default Experience;